import $ from 'jquery';

function contentToggleHandler() {
  $('.content-toggler-btn').on('click', function() {
    var ele = $(this);
    var parent = ele.parent().parent();
    var hiddenContent = parent.find('.hidden-content');

    if (!hiddenContent) {
      return;
    }

    if (hiddenContent.hasClass('d-none')) {
      hiddenContent.removeClass('d-none');
      ele.removeClass('fa-chevron-down');
      ele.addClass('fa-chevron-up');
    } else {
      hiddenContent.addClass('d-none');
      ele.removeClass('fa-chevron-up');
      ele.addClass('fa-chevron-down');
    }
  });
}

export { contentToggleHandler };
