import $ from 'jquery';

function c2cEmailRender() {
  var ele = $('.c2c_email');

  if (ele.length == 0) {
    return;
  }

  ele.html('info@c2cboat.com');
  ele.closest("a").attr("href", "mailto:info@c2cboat.com")
}

function contactUsEmailRender() {
  var ele = $('.contact_us_email');

  if (ele.length == 0) {
    return;
  }

  ele.html('info@c2cboat.com');
}

export { c2cEmailRender, contactUsEmailRender };
