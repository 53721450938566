import $ from 'jquery';

function menue_dropdown() {
  var menuWrapper = $('#menu-wrapper');

  if (menuWrapper.length == 0) {
    return;
  }

  $('#menueIcon').on('click', function() {
    $(this).addClass('hide');
    $('#closeIicon').removeClass('hide');
    $('#navbarToggleContent').removeClass('hide');
  });

  $('#closeIicon').on('click', function() {
    $(this).addClass('hide');
    $('#menueIcon').removeClass('hide');
    $('#navbarToggleContent').addClass('hide');
  });
}

export { menue_dropdown };
