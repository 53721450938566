require("@fortawesome/fontawesome-free");
require("popper.js");
require("bootstrap");
require("jquery-validation");
import $ from 'jquery';
window.$ = $;

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"
import { menue_dropdown } from "../components/menu_dropdown";
import { showMoreSeeLess } from "../components/show-more-see-less";
import { contentToggleHandler } from "../components/toggle-content";
import { c2cEmailRender, contactUsEmailRender } from "../components/c2c_email"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

document.addEventListener("turbolinks:load", function() {
  console.log('js working');

  menue_dropdown();
  showMoreSeeLess();
  contentToggleHandler();
  c2cEmailRender();
  contactUsEmailRender();
})
