import $ from 'jquery';

function showMoreSeeLess() {
  $('.show-more-btn').on('click', function() {
    var ele = $(this);
    var parents = ele.parents();

    if (parents.find('.hidden-content').hasClass('d-none')) {
      parents.find('.hidden-content').removeClass('d-none');
      ele.html('See less');
    } else {
      parents.find('.hidden-content').addClass('d-none');
      ele.html('See more');
    }
  });
}

export { showMoreSeeLess };
